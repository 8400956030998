import $ from "jquery";
import "../../assets/libs/bootstrap-5.0.2-dist/js/bootstrap.bundle.min.js";
import * as AOS from "../../assets/libs/aos";
import Footer from "@/components/footer/Footer";
import Header from "@/components/header/header";
import Breadcrumb from "@/components/breadcrumb/breadcrumb";
import Swal from "sweetalert2";
import Toolbar from '@/components/toolbar/toolbar';

const captchagen = require("captchagen");

// import "jquery-ui";
// import "jquery-ui-css";
export default {
  name: "Contact",
  data() {
    return {
      titleList: [
        {
          id: 1,
          title: "ข้อมูลผลิตภัณฑ์",
          title_en: "Product Information",
        },
        {
          id: 2,
          title: "ข้อมูลผู้แทนจำหน่าย",
          title_en: "Distributor Information",
        },
        {
          id: 3,
          title: "แค็ตตาล็อก",
          title_en: "Catalog",
        },
        {
          id: 4,
          title: "ขอตัวอย่างกระจก",
          title_en: "Request Glass Example",
        },
        {
          id: 5,
          title: "มอก.",
          title_en: "TIS",
        },
        {
          id: 6,
          title: "Company Profile",
          title_en: "Company Profile",
        },
        {
          id: 7,
          title: "แนะนำ/วางสเปคกระจก",
          title_en: "Recommend and Product Spec",
        },
        {
          id: 8,
          title: "คำนวณทางเทคนิค",
          title_en: "Technical Casculate",
        },
      ],
      productList: [
        {
          id: 1,
          title: "กระจกโฟลตใส",
          title_en: "Clear Float Glass",
          name: "กระจกโฟลตใส",
        },
        {
          id: 2,
          title: "กระจกโฟลต เอเนอร์ยีกรีน",
          title_en: "Energy Green Float Glass",
          name: "กระจกโฟลต เอเนอร์ยีกรีน",
        },
        {
          id: 3,
          title: "กระจกโฟลต โอเชียนกรีน",
          title_en: "Ocean Green Float Glass",
          name: "กระจกโฟลต โอเชียนกรีน",
        },
        {
          id: 4,
          title: "กระจกโฟลต ดาร์กเกรย์",
          title_en: "Dark Grey Float glass",
          name: "กระจกโฟลต ดาร์กเกรย์",
        },
        {
          id: 5,
          title: "กระจกโฟลต ยูโรเกรย์",
          title_en: "Euro Grey Float Glass",
          name: "กระจกโฟลต ยูโรเกรย์",
        },
        {
          id: 6,
          title: "กระจกโฟลต ยูโรบรอนซ์",
          title_en: "Euro Bronze Float Glass",
          name: "กระจกโฟลต ยูโรบรอนซ์",
        },
        {
          id: 7,
          title: "กระจกโฟลต สกายบลู",
          title_en: "Sky Blue Float Glass",
          name: "กระจกโฟลต สกายบลู",
        },
        {
          id: 8,
          title: "กระจกเงา มิโรแทค",
          title_en: "Mirror MirroTAG",
          name: "กระจกเงา มิโรแทค",
        },
        {
          id: 9,
          title: "กระจกสะท้อนความร้อน<br>โซลาร์แทค พลัส",
          title_en: "SolarTAG Plus",
          name: "กระจกสะท้อนความร้อน โซลาร์แทค พลัส",
        },

        {
          id: 10,
          title: "กระจกโลว-อี สต๊อปเรย์",
          title_en: "Low-e StopRAY",
          name: "กระจกโลว-อี สต๊อปเรย์",
        },
        {
          id: 11,
          title: "กระจกเทมเปอร์ เทมแทค",
          title_en: "Tempered Glass TemTAG",
          name: "กระจกเทมเปอร์ เทมแทค",
        },
        {
          id: 12,
          title: "กระจกนิรภัยหลายชั้น ลามิแทค",
          title_en: "Laminated Glass LamiTAG",
          name: "กระจกนิรภัยหลายชั้น ลามิแทค",
        },
        {
          id: 13,
          title: "กระจกฉนวน แพร์แทค",
          title_en: "Insulated Glass PairTAG",
          name: "กระจกฉนวน แพร์แทค",
        },
        {
          id: 14,
          title: "กระจกอบความร้อนพิมพ์ลาย ดิพแทค",
          title_en: "Digital Printing Glass DipTAG",
          name: "กระจกอบความร้อนพิมพ์ลาย ดิพแทค",
        },
        {
          id: 15,
          title: "กระจกอาคารผสานเซลล์แสงอาทิตย์",
          title_en: "Building Intergreted Photovotaics (BIPV)",
          name: "กระจกอาคารผสานเซลล์แสงอาทิตย์",
        },
        {
          id: 16,
          title: "กระจกกันกระสุนลามิแทค<br>บีอาร์",
          title_en: "Bullet Resistant Glass LamiTAG BR",
          name: "กระจกกันกระสุนลามิแทค บีอาร์",
        },
        {
          id: 17,
          title: "กระจกเสริมลวด",
          title_en: "Wire Glass ",
          name: "กระจกเสริมลวด",
        },
        {
          id: 18,
          title: "อื่นๆ",
          title_en: "Other",
          name: "",
        },
      ],
      name: "",
      line_id: "",
      company: "",
      product_id: [],
      list_occupation: [
        { title: "เจ้าของบ้าน/อาคาร" ,title_en: "House / Building Owner"},
        { title: "สถาปนิก/นักออกแบบ" ,title_en: "Architect / Designer"},
        { title: "ช่างติดตั้งกระจก" ,title_en: "Glass Installer"},
        { title: "ผู้รับเหมาก่อสร้าง" ,title_en: "Building Contractor"},
        { title: "นักพัฒนาที่ดิน/เจ้าของโครงการ" ,title_en: "Land Developer / Project Owner"},
        { title: "ผู้แปรรูปกระจก" ,title_en: "Glass Fabricator"},
        { title: "ผู้ผลิตประตู/หน้าต่าง/บานสำเร็จ" ,title_en: "Door Manufacturer / Window"},
        { title: "ฝ่ายจัดซื้อ จัดหาผลิตภัณฑ์" ,title_en: "Product Purchasing"},
        { title: "อาจาร์ย/นักศึกษา/นักเรียน" ,title_en: "Teacher / Student"},
        { title: "ร้านค้า/ผู้แทนจำหน่ายกระจก" ,title_en: "Store / Glass Distributor"},
        { title: "อื่น ๆ" ,title_en: "Other"},
      ],
      occupation: "",
      contact_topic_id: [],
      email: "",
      detail: "",
      telephone: "",
      subject: "",
      chk: false,
      chk_func: true,
      // eslint-disable-next-line
      reg_email:
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      txt: "",
      captchaUri: "",
      captchaText: "",
      captcha: "",
      center: { lat: 13, lng: 101 },
      markers: [
        {
          name: "เอจีซี สำนักงานใหญ่ และโรงงาน เอจีซี จ.สมุทรปราการ",
          addr: `200 หมู่1 ถนนสุขสวัสดิ์ ตำบลปากคลองบางปลากด อำเภอพระสมุทรเจดีย์ จังหวัด สมุทรปราการ 10290
                เวลาทำการ 8.00 - 17.00 น.
                โทร : 0-2815-5000
                แฟกซ์ : 0-2815-7375`,
          position: { lat: 13.6042287, lng: 100.5562787 },
          link: "https://www.google.com/maps/place/%E0%B8%9A%E0%B8%A3%E0%B8%B4%E0%B8%A9%E0%B8%B1%E0%B8%97+%E0%B9%80%E0%B8%AD%E0%B8%88%E0%B8%B5%E0%B8%8B%E0%B8%B5+%E0%B9%81%E0%B8%9F%E0%B8%A5%E0%B8%97%E0%B8%81%E0%B8%A5%E0%B8%B2%E0%B8%AA+(%E0%B8%9B%E0%B8%A3%E0%B8%B0%E0%B9%80%E0%B8%97%E0%B8%A8%E0%B9%84%E0%B8%97%E0%B8%A2)+%E0%B8%88%E0%B8%B3%E0%B8%81%E0%B8%B1%E0%B8%94+(%E0%B8%A1%E0%B8%AB%E0%B8%B2%E0%B8%8A%E0%B8%99)/@13.6047344,100.5553106,19z/data=!4m9!1m2!2m1!1z4LmA4Lit4LiI4Li14LiL4Li1IOC4quC4s-C4meC4seC4geC4h-C4suC4meC5g-C4q-C4jeC5iCA!3m5!1s0x30e2a720a6454e2d:0x63781d3340967ea4!8m2!3d13.604291!4d100.5562971!15sCjfguYDguK3guIjguLXguIvguLUg4Liq4Liz4LiZ4Lix4LiB4LiH4Liy4LiZ4LmD4Lir4LiN4LmIWjwiOuC5gOC4rSDguIjguLUg4LiL4Li1IOC4quC4s-C4meC4seC4geC4h-C4suC4mSDguYPguKvguI3guYiSAQ9mdXJuaXR1cmVfc3RvcmWaASNDaFpEU1VoTk1HOW5TMFZKUTBGblNVTnpiMlV5ZWtoQkVBRQ?hl=th",
        },
        {
          name: "โรงงาน เอจีซี จ.ชลบุรี",
          addr: `700/22 หมู่ 6 ต. หนองไม้แดง อ. เมือง จ. ชลบุรี 20000
                เวลาทำการ 8.00 - 17.00 น.
                โทร : 0-3821-3063
                แฟกซ์ : 0-3821-3066`,
          position: { lat: 13.4251721, lng: 101.0186613 },
          link: "https://www.google.com/maps/place/%E0%B8%9A%E0%B8%A3%E0%B8%B4%E0%B8%A9%E0%B8%B1%E0%B8%97+%E0%B9%80%E0%B8%AD%E0%B8%88%E0%B8%B5%E0%B8%8B%E0%B8%B5+%E0%B9%81%E0%B8%9F%E0%B8%A5%E0%B8%97%E0%B8%81%E0%B8%A5%E0%B8%B2%E0%B8%AA+(%E0%B8%9B%E0%B8%A3%E0%B8%B0%E0%B9%80%E0%B8%97%E0%B8%A8%E0%B9%84%E0%B8%97%E0%B8%A2)+%E0%B8%88%E0%B8%B3%E0%B8%81%E0%B8%B1%E0%B8%94+(%E0%B8%A1%E0%B8%AB%E0%B8%B2%E0%B8%8A%E0%B8%99)/@13.4247491,101.0179075,19.01z/data=!4m19!1m13!4m12!1m4!2m2!1d100.572213!2d13.8068423!4e1!1m6!1m2!1s0x311d3760ab10408b:0x428636c55952ed11!2zNzAwLzIyIOC4q-C4oeC4ueC5iCA2IOC4lS4g4Lir4LiZ4Lit4LiH4LmE4Lih4LmJ4LmB4LiU4LiHIOC4rS4g4LmA4Lih4Li34Lit4LiHIOC4iC4g4LiK4Lil4Lia4Li44Lij4Li1IDIwMDAw!2m2!1d101.0186708!2d13.4249239!3m4!1s0x311d3760ab10408b:0x428636c55952ed11!8m2!3d13.4249239!4d101.0186708",
        },
        {
          name: "โรงงาน เอจีซี จ.ระยอง",
          addr: `7/104 หมู่ 4 ทางหลวงหมายเลข 331 ต. มาบยางพร อ.ปลวกแดง จ. ระยอง 21140
                เวลาทำการ 8.00 - 17.00 น.
                โทร : 0-3801-6888
                แฟกซ์ : 0-3801-6899`,
          position: { lat: 12.9904088, lng: 101.1009019 },
          link: "https://www.google.com/maps/place/%E0%B8%9A%E0%B8%A3%E0%B8%B4%E0%B8%A9%E0%B8%B1%E0%B8%97+%E0%B9%80%E0%B8%AD%E0%B8%88%E0%B8%B5%E0%B8%8B%E0%B8%B5+%E0%B9%81%E0%B8%9F%E0%B8%A5%E0%B8%97%E0%B8%81%E0%B8%A5%E0%B8%B2%E0%B8%AA+(%E0%B8%9B%E0%B8%A3%E0%B8%B0%E0%B9%80%E0%B8%97%E0%B8%A8%E0%B9%84%E0%B8%97%E0%B8%A2)+%E0%B8%88%E0%B8%B3%E0%B8%81%E0%B8%B1%E0%B8%94+(%E0%B8%A1%E0%B8%AB%E0%B8%B2%E0%B8%8A%E0%B8%99)+%E0%B9%82%E0%B8%A3%E0%B8%87%E0%B8%87%E0%B8%B2%E0%B8%99%E0%B8%A3%E0%B8%B0%E0%B8%A2%E0%B8%AD%E0%B8%87/@12.9906498,101.1009585,18.39z/data=!4m19!1m13!4m12!1m4!2m2!1d100.5633683!2d13.8132789!4e1!1m6!1m2!1s0x3102c3b0f47229c1:0xa4548a6adf5f0dc0!2z4LmC4Lij4LiH4LiH4Liy4LiZIOC5gOC4reC4iOC4teC4i-C4tSDguIgu4Lij4Liw4Lii4Lit4LiH!2m2!1d101.1016393!2d12.9904836!3m4!1s0x3102c3b0f47229c1:0xa4548a6adf5f0dc0!8m2!3d12.9904836!4d101.1016393",
        },
      ],
      list_question: [
        {
          type: 2,
          id: 1,
          question: "ผู้แทนจำหน่ายมีที่ไหนบ้าง",
          question_en: "Where are the dealers?",
          answer: `สามารถค้นหาผู้แทนจำหน่ายของเอจีซีที่ท่านสะดวก คลิก : <a href="https://www.agc-flatglass.co.th/SalesChannel">https://www.agc-flatglass.co.th/SalesChannel</a>`,
          answer_en: `You can search for AGC dealers that are convenient for you, click : <a href="https://www.agc-flatglass.co.th/SalesChannel">https://www.agc-flatglass.co.th/SalesChannel</a>`,
        },
        {
          type: 2,
          id: 2,
          question: "ดาวน์โหลดโบว์ชัวร์ได้ที่ไหน",
          question_en: "Where can I download the brochure?",
          answer: `สามารถค้นหาดาวน์โหลดโบว์ชัวร์ คลิก : <a href="https://www.agc-flatglass.co.th/technical-support">https://www.agc-flatglass.co.th/technical-support</a>`,
          answer_en: `You can search for brochure downloads, click : <a href="https://www.agc-flatglass.co.th/technical-support">https://www.agc-flatglass.co.th/technical-support</a>`,
        },
        {
          type: 2,
          id: 3,
          question: "อยากซื้อกระจกเอจีซีตรงกับบริษัทได้หรือไม่",
          question_en: "Can I buy AGC glass directly with the company?",
          answer: `บริษัทฯ มีนโยบายขายกระจกผ่านช่องทางผู้แทนจำหน่าย ท่านสามารถค้นหาผู้แทนจำหน่ายของเอจีซีที่ท่านสะดวกได้ที่ คลิก : <a href="https://www.agc-flatglass.co.th/SalesChannel">https://www.agc-flatglass.co.th/SalesChannel</a>`,
          answer_en: `The Company has a policy to sell glass through dealers. You can search for AGC dealers that are convenient for you, click : <a href="https://www.agc-flatglass.co.th/SalesChannel">https://www.agc-flatglass.co.th/SalesChannel</a>`,
        },
        {
          type: 2,
          id: 4,
          question: "ขอสเปคกระจกสำหรับสร้างอาคารขนาดเล็ก",
          question_en: "Please give me glass specifications for constructing a small building.",
          answer: `สามารถค้นหากระจกที่ท่านต้องการได้ที่ Find Your Glass : <a href="https://www.agc-flatglass.co.th/findyourglass">https://www.agc-flatglass.co.th/findyourglass</a>`,
          answer_en: `You can find the glass you want at Find Your Glass : <a href="https://www.agc-flatglass.co.th/findyourglass">https://www.agc-flatglass.co.th/findyourglass</a>`,
        },
        {
          type: 2,
          id: 5,
          question: "ขอสเปคกระจกสำหรับสร้างอาคารขนาดใหญ่",
          question_en: "Please give me glass specifications for constructing a large building.",
          answer: `สามารถค้นหากระจกที่ท่านต้องการได้ที่ Find Your Glass : <a href="https://www.agc-flatglass.co.th/findyourglass">https://www.agc-flatglass.co.th/findyourglass</a>`,
          answer_en: `You can find the glass you want at Find Your Glass : <a href="https://www.agc-flatglass.co.th/findyourglass">https://www.agc-flatglass.co.th/findyourglass</a>`,
        },
        {
          type: 2,
          id: 6,
          question: "ขอข้อมูลกระจก",
          question_en: "Please give me the glass information.",
          answer: `สามารถค้นหาข้อมูลกระจกที่ท่านต้องการได้ที่ Product : <a href="https://www.agc-flatglass.co.th/product">https://www.agc-flatglass.co.th/product</a>`,
          answer_en: `You can find the glass you want at Product : <a href="https://www.agc-flatglass.co.th/product">https://www.agc-flatglass.co.th/product</a>`,
        },
        {
          type: 2,
          id: 7,
          question: "ขอข้อมูล มอก. กระจก",
          question_en: "Please give me the TIS’ information of the glass.",
          answer: `สามารถค้นหาข้อมูลกระจกที่ท่านต้องการได้ที่ ดาวน์โหลด : <a href="https://www.agc-flatglass.co.th/technical-support">https://www.agc-flatglass.co.th/technical-support</a>`,
          answer_en: `You can find the glass you want by downloading at : <a href="https://www.agc-flatglass.co.th/technical-support">https://www.agc-flatglass.co.th/technical-support</a>`,
        },
        {
          type: 2,
          id: 8,
          question: "กระจกโลว-อี คืออะไร",
          question_en: "What is Low-e glass?",
          answer: `สามารถค้นหาข้อมูลกระจกที่ท่านต้องการได้ที่ Product - กระจกโลวอี ประสิทธิภาพสูง Product : <a href="https://www.agc-flatglass.co.th/product-content/10">https://www.agc-flatglass.co.th/product/high-efficiency-low-e</a>`,
          answer_en: `You can find the glass information you want at High Efficiency Low-e Glass Product : <a href="https://www.agc-flatglass.co.th/product-content/10">https://www.agc-flatglass.co.th/product/high-efficiency-low-e</a>`,
        },
        {
          type: 2,
          id: 9,
          question: "อาคาร 23 เมตร ขึ้นไปควรใช้กระจกอะไร",
          question_en: "For the buildings of 23 meters or more height, which glass should be used?",
          answer: `เอจีซีแนะนำให้ใช้กระจกลามิเนต ตามกฏกระทรวง ฉบับที่ 48 (พ.ศ. 2540) ออกตามความในพระราชบัญญัติควบคุมอาคาร พ.ศ. 2522  หากท่านต้องการข้อมูลเพิ่มเติมสามารถส่งคำขอได้ที่ : <a href="https://www.agc-flatglass.co.th/technical-support">https://www.agc-flatglass.co.th/technical-support</a>`,
          answer_en: `AGC recommends the use of laminated glass according to the Ministerial Regulation No. 48 (B.E.2540) issued under the Building Control Act B.E.2522. For more information, the request can be sent to : <a href="https://www.agc-flatglass.co.th/technical-support">https://www.agc-flatglass.co.th/technical-support</a>`,
        },
        {
          type: 1,
          id: 10,
          question: "หากบ้านติดถนน ควรใช้กระจกชนิดใดเพื่อป้องกันเสียงรบกวน",
          question_en: "If the house is on the road, which type of glass should be used to prevent noise?",
          answer: `เอจีซีแนะนำให้ใช้กระจกลามิเนต เนื่องจากกระจกลามิเนตมีคุณสมบัติในการป้องกันเสียงรบกวนได้ดีกว่ากระจกแผ่นเดี่ยว`,
          answer_en: `AGC recommends using laminated glass because the laminated glass has better soundproofing properties than single-pane glass.`,
        },
        {
          type: 2,
          id: 11,
          question: "วิธีการทำความสะอาดกระจกเงาที่ถูกต้อง",
          question_en: "The correct way to clean the glass.",
          answer: `สามารถค้นหาวิธีการทำความสะอาดกระจกเงาได้ที่ ดาวน์โหลด : <a href="https://www.agc-flatglass.co.th/technical-support">https://www.agc-flatglass.co.th/technical-support</a>`,
          answer_en: `You can find out how to clean the flat glass by downloading at : <a href="https://www.agc-flatglass.co.th/technical-support">https://www.agc-flatglass.co.th/technical-support</a>`,
        },
        {
          type: 2,
          id: 12,
          question: "กระจกเทมเปอร์และฮีตเตรงเทนแตกต่างกันอย่างไร",
          question_en: "What is the difference between tempered and heat-strengthened glass?",
          answer: `
            <p>กระจกเทมเปอร์และกระจกฮีตเตรงเทนมีกระบวนการในการผลิตคล้ายกัน คือ การอบกระจกด้วยความร้อนเพื่อให้ได้กระจกที่มีคุณสมบัติแข็งแรงขึ้นกว่ากระจกทั่วไปในความหนาเดียวกัน แต่มีบางเรื่องที่แตกต่างกันได้แก่</p>
            <p>ความแข็งแกร่ง</p>
            <ul>
                <li>กระจกเทมเปอร์ มีความแข็งแกร่งกว่ากระจกทั่วไปในความหนาเดียวกัน 3-5 เท่า</li>
                <li>กระจกฮีตเสตรงเทน มีความแข็งแกร่งกว่ากระจกทั่วไปในความหนาเดียวกัน 2 เท่า</li>
            </ul>
            <p>ลักษณะการแตก</p>
            <ul>
                <li>กระจกเทมเปอร์เมื่อแตก จะแตกกระจายออกเป็นเม็ดเล็กๆ คล้ายเมล็ดข้าวโพด เศษกระจกมีคม</li>
                <li>กระจกฮีตเสตรงเทนเมื่อแตก รอยแตกจะวิ่งเข้าไปที่ขอบกระจกเสมอ โดยเอจีซีแนะนำให้ติดตั้งกระจกฮีตเสรงเทนเข้ากับกรอบอาคารแบบ 4 ด้านเสมอ เพื่อป้องกันกระจกหลุดล่วงเมื่อกระจกแตก</li>
            </ul>
            `,
            answer_en: `
            <p>Tempered glass and heat-strengthened glass have a similar production process which is to heat glass to obtain stronger glass properties than normal glass of the same thickness. However, there are some different things</p>
            <p>Strength</p>
            <ul>
                <li>Tempered glass is 3-5 times stronger than general glass of the same thickness.</li>
                <li>•	The heat-strengthened glass is twice stronger than general glass of the same thickness.</li>
            </ul>
            <p>Breakage</p>
            <ul>
                <li>When the tempered glass is broken, it will be broken up into small granules resembling corn kernels with sharp shards of glass.</li>
                <li>When the heat-strengthened glass is broken, the crack will always run into the edge of the glass. AGC always recommends to install heat-strengthened glass to the 4-sided building frame to prevent the glass from falling off when it is broken.</li>
            </ul>
            `,
        },
        {
          type: 2,
          id: 13,
          question: "ฟิล์ม PVB และ SGP ของกระจกลามิเนตแตกต่างกันอย่างไร",
          question_en: "What is the difference between PVB and SGP film of laminated glass?",
          answer: `
            <p>ฟิล์ม PVB และ SGP เป็นฟิล์มที่นิยมนำมาใช้ในกระบวนการผลิตกระจกนิรภัยลามิเนต โดยฟิล์มทั้ง 2 ชนิด มีคุณสมบัติที่แตกต่างกัน คือ</p>
            <p>PVB Film</p>
            <ul>
                <li>เหมาะสำหรับงานกรอบอาคาร และงานที่มีกรอบกระจก</li>
                <li>มีสีฟิล์มให้เลือกหลากหลาย เพื่อการออกแบบที่แตกต่าง</li>
                <li>ราคาย่อมเยา</li>
            </ul>
            <p>SGP Film</p>
            <ul>
                <li>เหมาะสำหรับงานที่ไม่จำเป็นต้องมีกรอบกระจกครบ 4 ด้าน เนื่องจากมีคุณสมบัติในการกันความชื้นได้ดี จึงสามารถใช้เป็นกระจกสำหรับราวกันตก หรือสระว่ายน้ำได้</li>
                <li>แข็งแรงทนทานต่อแรงกระแทกและการฉีกขาด</li>
                <li>มีสีใสเพียงสีเดียว</li>
                <li>ราคาสูงกว่า PVB Film</li>
            </ul>
            `,
            answer_en: `
            <p>PVB and SGP films are commonly used in the production of laminated safety glass. Both types of films have different properties as follows</p>
            <p>PVB Film</p>
            <ul>
                <li>Suitable for building frames and works with glass frames</li>
                <li>Available in a variety of film colors for a different design</li>
                <li>Affordable price</li>
            </ul>
            <p>SGP Film</p>
            <ul>
                <li>Suitable for work that does not require a full 4-sided glass frame due to its good moisture-proof properties. Therefore, it can be used as glass for a railing or for a swimming pool.</li>
                <li>Strong and resistant to impact and tear.</li>
                <li>Available in only one clear color.</li>
                <li>Price is higher than PVB Film.</li>
            </ul>
            `,
        },
        {
          type: 1,
          id: 14,
          question:
            "ฟิล์ม SPG และ Sentry Glass และ Ionoplast และ INP ต่างกันอย่างไร",
            question_en:
            "What is the difference between SPG film and Sentry Glass and Innoplast and INP?",
          answer:
            "ในท้องตลาดเรามักจะพบว่าคำเรียกชนิดฟิล์มที่มีคุณสมบัติป้องกันความชื้นและแข็งแรงทนทานต่อแรกกระแทก การฉีกขาด ถูกเรียกในชื่อที่แตกต่างกันออกไป บางก็เรียกว่าฟิล์ม SPG (Sentry Glass) หรือ INP (Ionoplast) ไม่ว่าจะเรียกว่า SPG และ INP คือฟิล์มชนิดเดียวกัน",
            answer_en:
            "In the market, we often find that the term film with moisture barrier properties and durability against impact, tearing is referred to by different names. Some are also called SPG (Sentry Glass) or INP (Ionoplast) films. Both SPG and INP are the same film.",
        },
        {
          type: 1,
          id: 15,
          question: "ความหนาของกระจก Laminated มีความหนาสูงที่สุดเท่าไหร่",
          question_en: "What is the maximum thickness of laminated glass?",
          answer: "กระจกนิรภัยลามิเนตของเอจีซี สามารถผลิตโดยมีความหนารวมสูงสุดที่ 80 มิลลิเมตร",
          answer_en: "AGC laminated safety glass can be produced with a maximum total thickness of 80 mm.",
        },
        {
          type: 2,
          id: 16,
          question: "SHGC และ SC ต่างกันอย่างไร",
          question_en: "What is the difference between SHGC and SC?",
          answer: `
            <p>SHGC คือ สัมประสิทธิ์การถ่ายเทความร้อนรวมจากรังสีอาทิตย์ หรือ G Value หรือ Solar Factor (SF) ซึ่งประกอบด้วยความร้อนทางตรงและการแผ่รังสีเข้าอาคารของพลังงานที่สะสมอยู่ในกระจก</p>
            <p>SC คือ สัมประสิทธิ์การบังแดดจากรังสีอาทิตย์ ซึ่งเป็นค่าคุณสมบัติเฉพาะของกระจกที่ใช้เป็นผนัง เมื่อเปรียบเทียบกับกระจกใส 3 มม.</p>
            `,
            answer_en: `
            <p>SHGC is the total heat transfer coefficient from solar radiation or G Value or Solar Factor (SF) which consists of direct heat and radiation entering the building of energy stored in the glass.</p>
            <p>SC is the shading coefficient from solar radiation which is the property of the glass used as a wall when compared to 3 mm. clear glass.</p>
            `,
        },
      ],
      form: {
        name: "",
        line_id: "",
        product_id: [],
        company: "",
        occupation: null,
        contact_topic_id: [],
        email: "",
        detail: "",
        telephone: "",
      },
    };
  },
  methods: {
    gotoPage(url){
      location.href = url;
  },
    validateInput() {
      // Check if the input value contains non-numeric characters
      if (/[^0-9]/g.test(this.form.telephone)) {
        // If so, remove them from the value
        this.form.telephone = this.form.telephone.replace(/[^0-9]/g, "");
      }
    },
    isEmailValid: function () {
      return this.email == ""
        ? ""
        : this.reg_email.test(this.email)
        ? "has-success"
        : "has-error";
    },
    chkCaptcha: function () {
      return this.email == ""
        ? ""
        : this.reg_email.test(this.email)
        ? "has-success"
        : "has-error";
    },
    acceptNumber() {
      var x = this.telephone
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.telephone = !x[2]
        ? x[1]
        : x[1] + "-" + x[2] + (x[3] ? "-" + x[3] : "");
      // this.telephone = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    },
    async sendForm() {
      if (this.captcha != this.captchaText) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Captcha ไม่ถูกต้อง",
          confirmButtonText: "ปิด",
          // footer: '<a href="">Why do I have this issue?</a>'
        });
        return false;
      }
      if (
        !this.form.name ||
        !this.form.product_id ||
        !this.chk ||
        !this.form.occupation ||
        !this.form.contact_topic_id ||
        !this.form.telephone ||
        !this.form.email
      )
        return false;
      let obj = {
        request: this.form,
      };
      await this.$axios
        .post("/api/send/contact_us", obj)
        .then((response) => {
          if (response.data.code == 200) {
            Swal.fire({
              icon: "success",
              text: "ส่งข้อมูลสำเร็จ",
              confirmButtonText: "ปิด",
              // footer: '<a href="">Why do I have this issue?</a>'
            });
            this.form = {
              name: "",
              line_id: "",
              product_id: [],
              company: "",
              occupation: null,
              contact_topic_id: [],
              email: "",
              detail: "",
              telephone: "",
            };
            this.chk = "";
            this.getCaptcha();
          }
        })
        .catch(() => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "ส่งข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง",
            confirmButtonText: "ปิด",
            // footer: '<a href="">Why do I have this issue?</a>'
          });
        });
    },
    getCaptcha() {
      let captcha = captchagen.create();

      this.captchaText = captcha.text(); // Returns captcha text (randomly generated by default)
      captcha.height(); // Returns captcha height (150 by default)
      captcha.width(); // Returns captcha width (300 by default)
      captcha.generate(); // Draws the image to the canvas

      /* call `generate()` before running the below */

      this.captchaUri = captcha.uri(); // outputs png data-uri. works sync and async (cb is optional)
    },
    zoomout() {
      Swal.fire({
        html: `<img src="${this.captchaUri}">`,
        confirmButtonText: "ปิด",
        // footer: '<a href="">Why do I have this issue?</a>'
      });
    },
    gotoMap(evt) {
      console.log(evt);
      window.open(evt.link);
      // window.open(`https://www.google.co.th/maps/place/13%C2%B047'11.4%22N+100%C2%B039'12.4%22E/@${evt.lat},${evt.lng},17z/data=!3m1!4b1!4m5!3m4!1s0x0:0xff0969742bb9e076!8m2!3d13.7865116!4d100.6534553?hl=th`)
      // location.href = `https://www.google.co.th/maps/@${evt.lat},${evt.lng},17z?hl=th`
    },
  },
  async mounted() {
    document.title = "AGC - Contact Us";
    AOS.init();
    resize();
    if (this.$route.query.message) {
      this.$refs.message.focus();
    }
    function resize() {
      if (window.innerWidth < 800)
        $(".footer").css({ position: "relative", top: "0px" });
    }
    // this.productList = await apiGet(product,{
    //     page : 1,
    //     limit : 100000000,
    //     sort : 'ASC',
    // })
    this.getCaptcha();
  },
  components: {
    Footer,
    Header,
    Breadcrumb,
    Toolbar,
  },
};
